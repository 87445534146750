/* global angular jQuery */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('modalEmployee', {
      templateUrl: 'employees/modal-employee.component.html',
      bindings: {
        employee: '<',
        args: '<',
        ossMenuSelector: '<',
        onClose: '&'
      },
      controller: ModalEmployeeController,
      controllerAs: 'vm'
    })

  ModalEmployeeController.$inject = ['OSS_TRANSLATIONS_DATA']
  function ModalEmployeeController (OSS_TRANSLATIONS_DATA) {
    var vm = this

    vm.maxRatingCount = 3
    vm.translations = OSS_TRANSLATIONS_DATA
    vm.getModalMargin = getModalMargin

    function getModalMargin () {
      try {
        var menuToShow = jQuery(vm.ossMenuSelector)
        var menuTop = menuToShow.offset().top + menuToShow.outerHeight()
        var usedTop = Math.min(140, menuTop)
        return usedTop + 'px auto'
      } catch (e) {
        return '4em auto'
      }
    }
  }
})()
