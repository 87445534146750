/* global angular */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('searchBarParticipantsNumber', {
      templateUrl: 'search-bar/search-bar-participants-number.component.html',
      bindings: {
        service: '<',
        currentInput: '=',
        participantsNumber: '=',
        nextInputStep: '&',
        isInFooter: '<'
      },
      controller: SearchBarParticipantsNumberController,
      controllerAs: 'vm'
    })

  SearchBarParticipantsNumberController.$inject = ['OSS_TRANSLATIONS_DATA', '$scope']
  function SearchBarParticipantsNumberController (OSS_TRANSLATIONS_DATA, $scope) {
    var vm = this

    $scope.$watch(function () { return vm.selectedService }, $onInit)

    vm.$onInit = $onInit
    vm.getCurrentSelectedItem = getCurrentSelectedItem

    vm.translations = OSS_TRANSLATIONS_DATA

    vm.childrenSelectableValues = []

    function $onInit () {
      var min = parseInt(vm.service.min_participant || "1")
      var max = parseInt(vm.service.max_participant || "9")

      if (!vm.participantsNumber || vm.participantsNumber < min || vm.participantsNumber > max) {
        vm.participantsNumber = min
      }
      
      vm.childrenSelectableValues = []

      for (var i = min; i <= max; i++) {
        var content = i === 1 ? vm.translations['1 participant'] : i + ' ' + vm.translations['participants']
        vm.childrenSelectableValues.push({ participantsNumber: i, content: content })
      }
    }

    function getCurrentSelectedItem () {
      return vm.childrenSelectableValues.find(function (item) {
        return item.participantsNumber === vm.participantsNumber
      })
    }
  }
})()
