/* global angular */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('searchBarCategoryAndService', {
      templateUrl: 'search-bar/search-bar-category-and-service.component.html',
      bindings: {
        allowedCategories: '<',
        selectedCategory: '=',
        allowedServices: '<',
        selectedService: '=',
        currentInput: '=',
        nextInputStep: '&',
        isInFooter: '<'
      },
      controller: SearchBarCategoryAndServiceController,
      controllerAs: 'vm'
    })

  SearchBarCategoryAndServiceController.$inject = ['OSS_TRANSLATIONS_DATA']
  function SearchBarCategoryAndServiceController (OSS_TRANSLATIONS_DATA) {
    var vm = this

    vm.translations = OSS_TRANSLATIONS_DATA
    vm.getCurrentLevelCategories = getCurrentLevelCategories
    vm.getCurrentLevelServices = getCurrentLevelServices
    vm.selectParentCategory = selectParentCategory

    function getCurrentLevelCategories () {
      if (!vm.allowedCategories) return []

      return vm.allowedCategories.filter(function (category) {
        return vm.selectedCategory ? category.parent_category_id === vm.selectedCategory.id : !category.parent_category_id
      })
    }

    function servicesOutsideCategories () {
      if (!vm.allowedCategories) return []

      return vm.allowedServices.filter(function (service) {
        return !vm.allowedCategories.find(function (category) {
          return category.offered_services.find(function (serviceInCategory) { return serviceInCategory.id === service.id })
        })
      })
    }

    function getCurrentLevelServices () {
      if (!vm.selectedCategory) return servicesOutsideCategories()

      return vm.allowedServices.filter(function (service) {
        return vm.selectedCategory.offered_services.find(function (otherService) { return otherService.id === service.id })
      })
    }

    function selectParentCategory () {
      if (!vm.selectedCategory) return
      vm.selectedCategory = vm.allowedCategories.find(function (parent) { return parent.id === vm.selectedCategory.parent_category_id }) || null
    }
  }
})()
