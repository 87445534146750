;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('mapSuppliers', {
      templateUrl: 'suppliers/map-suppliers.component.html',
      bindings: {
        ossMenuSelector: '<',
        data: '<'
      },
      controller: MapSuppliersController,
      controllerAs: 'vm'
    })

  MapSuppliersController.$inject = ['OSS_TRANSLATIONS_DATA', 'GeolocationServiceFactory', 'MapStyles', '$element', '$scope', '$compile', '$timeout']
  function MapSuppliersController (OSS_TRANSLATIONS_DATA, GeolocationServiceFactory, MapStyles, $element, $scope, $compile, $timeout) {
    var vm = this

    vm.translations = OSS_TRANSLATIONS_DATA
    vm.$onInit = $onInit
    vm.markers = []
    vm.selectedSupplier = null
    vm.infoWindow = null

    function $onInit () {
      if (typeof vm.data === 'string' && vm.data.startsWith('json:')) {
        vm.data = JSON.parse(jQuery('script[id="' + vm.data + '"]').text())
      }
      vm.mapContainer = $element.find('#oss-map-suppliers')[0]

      var mapConf = {}

      if (vm.data.args.style !== 'default' && MapStyles[vm.data.args.style]) {
        mapConf.styles = MapStyles[vm.data.args.style]
      } else {
        mapConf.styles = []
      }

      mapConf.styles.push({
        featureType: 'road',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      })

      vm.map = new google.maps.Map(vm.mapContainer, mapConf)
      initMap()
      
      if (vm.data.args.isInShowcase) {
        $scope.$on('showcase.set.suppliers', function (ev, data) {
          if (vm.data.args.marketplaceId === data.marketplaceId) {
            vm.data.suppliers = data.suppliers
            $timeout(initMap, 250)
          }
        })
      }
    }

    $scope.$watch(function () {
      return GeolocationServiceFactory.version
    }, function () {
      if (GeolocationServiceFactory.isLocationValid()) onLocationGot()
    })

    function initMap () {
      clearMarkers()
      var bounds = new google.maps.LatLngBounds()

      vm.data.suppliers.forEach(function (supplier) {
        if (!isNumeric(supplier.address.latitude) || !isNumeric(supplier.address.longitude)) {
          return console.error('Bad lng or lat for supplier ' + supplier.name)
        }

        var template = '<div class="oss-employees google-maps">' +
          '<center>' +
            '<img class="oss-logo-supplier-map" ng-src="{{ supplier.logo_url }}">' +
            '<div style="text-align: center">' +
              '<div class="employee-name">{{ supplier.name }}</div>' +
              '<a ng-click="vm.selectedSupplier = supplier">{{ vm.translations["see more"] }}</a>' +
            '</div>' +
          '</center>' +
        '</div>'

        var childScope = $scope.$new(false, $scope)
        childScope.supplier = supplier

        var compiledTemplate = $compile(template)(childScope)
        var infowindow = new google.maps.InfoWindow({ content: compiledTemplate[0] })
        var marker = new google.maps.Marker({
          position: { lat: supplier.address.latitude, lng: supplier.address.longitude },
          map: vm.map,
          title: supplier.name
        })
        
        marker.addListener('click', function () {
          infowindow.open(vm.map, marker)
        })

        vm.markers.push(marker)

        var loc = new google.maps.LatLng(marker.position.lat(), marker.position.lng())
        bounds.extend(loc)
      })

      if (vm.data.suppliers.length) {
        vm.map.fitBounds(bounds)
        vm.map.panToBounds(bounds)
      } else if (GeolocationServiceFactory.customerLatitude) {
        var latitude = GeolocationServiceFactory.customerLatitude
        var longitude = GeolocationServiceFactory.customerLongitude
        vm.map.setCenter(new google.maps.LatLng(latitude, longitude))
        vm.map.setZoom(12)
      } else {
        vm.map.setCenter(new google.maps.LatLng(0, 0))
        vm.map.setZoom(2)
      }

      if (vm.data.args.locationTrigger === 'onload') {
        GeolocationServiceFactory.refreshLocation()
      }
    }

    function clearMarkers () {
      for (var i = 0; i < vm.markers.length; i++) {
        vm.markers[i].setMap(null)
      }

      vm.markers = []
    }

    function isNumeric (val) {
      return Number(parseFloat(val)) === val
    }

    function onLocationGot () {
      var latitude = GeolocationServiceFactory.customerLatitude
      var longitude = GeolocationServiceFactory.customerLongitude
      var loc = new google.maps.LatLng(latitude, longitude)

      if (vm.infoWindow) vm.infoWindow.close()

      vm.infoWindow = new google.maps.InfoWindow({ map: vm.map })
      vm.infoWindow.setPosition({ lat: latitude, lng: longitude })
      vm.infoWindow.setContent(
        OSS_TRANSLATIONS_DATA[GeolocationServiceFactory.resolutionMode === 'geoloc' ? 'You are here' : 'Your search']
      )

      vm.map.setCenter(loc)
      vm.map.setZoom(vm.data.args.zoomAroundCustomer)
    }
  }
})()
