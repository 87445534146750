/* global angular jQuery */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('listSuppliers', {
      templateUrl: 'suppliers/list-suppliers.component.html',
      bindings: {
        ossMenuSelector: '<',
        data: '<'
      },
      controller: ListSuppliersController,
      controllerAs: 'vm'
    })

  ListSuppliersController.$inject = ['OSS_TRANSLATIONS_DATA', '$scope']
  function ListSuppliersController (OSS_TRANSLATIONS_DATA, $scope) {
    var vm = this

    vm.translations = OSS_TRANSLATIONS_DATA

    vm.showAll = false
    vm.$onInit = $onInit
    vm.getLimitTo = getLimitTo
    
    vm.renderableSuppliers = []
    vm.selectedSupplier = null

    function $onInit () {
      if (typeof vm.data === 'string' && vm.data.startsWith('json:')) {
        vm.data = JSON.parse(jQuery('script[id="' + vm.data + '"]').text())
      }

      vm.renderableSuppliers = vm.data.suppliers

      if (vm.data.args.isInShowcase) {
        $scope.$on('showcase.set.suppliers', function (ev, data) {
          if (vm.data.args.marketplaceId === data.marketplaceId) {
            vm.data.suppliers = data.suppliers
            vm.renderableSuppliers = vm.data.suppliers
          }
        })
      }
    }

    function getLimitTo () {
      return vm.showAll ? Infinity : vm.data.args.listSize
    }
  }
})()
