/* global angular google jQuery */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .factory('GeolocationServiceFactory', GeolocationServiceFactory)

  GeolocationServiceFactory.$inject = ['$q']

  function GeolocationServiceFactory ($q) {
    /**
     * (customerLatitude, customerLongitude): number mandatory if found
     * resolutionMode: string : 'geoloc', 'input'
     */
    var service = {
      /** the version number of the location, to easily track changes of location */
      version: 0,

      /** is a request to navigator.geolocation running ? */
      requesting: false,

      /** the latitude if the position is found */
      customerLatitude: null,

      /** the longitude if the position is found */
      customerLongitude: null,

      /** how was found the location ? Possible values are [geoloc, input] */
      resolutionMode: null,

      /** optional : the google result entry if the location has been found using google geocode api  */
      googleResult: null,

      /** optional : the country to which the geocode requests will be restricted */
      country: null,

      setLocation: setLocation,
      refreshLocation: refreshLocation,
      isLocationValid: isLocationValid,
      translateAddressOrZipToLocation: translateAddressOrZipToLocation,
      getDistanceInKM: getDistanceInKM,
      extractGeocodingFieldFromGoogleResult: extractGeocodingFieldFromGoogleResult
    }

    return service

    function setLocation (lat, lng, resolutionMode, googleResult) {
      service.customerLatitude = lat
      service.customerLongitude = lng
      service.resolutionMode = resolutionMode
      service.version++

      if (typeof googleResult === 'undefined') {
        $q(function (resolve, reject) {
          var geocoder = new google.maps.Geocoder()
          var req = { location: { lat: lat, lng: lng } }
          if (service.country) req.componentRestrictions = { country: service.country }
          geocoder.geocode(req, function (response, status) {
            status !== google.maps.GeocoderStatus.OK ? reject() : resolve(response)
          })
        }).then(function (response) {
          var prefered = response.find(function (_) {
            return _.types.indexOf('locality') !== -1
          })
          service.googleResult = (prefered || response[0])
        })
      } else {
        service.googleResult = googleResult
      }
    }

    function refreshLocation () {
      if (service.requesting) return
      service.requesting = true

      requestCustomerLocationHtml5().then(function (coords) {
        service.requesting = false
        setLocation(coords.lat, coords.lng, 'geoloc')
      })
    }

    function isLocationValid () {
      return service.customerLatitude !== null && service.customerLongitude !== null
    }

    function requestCustomerLocationHtml5 () {
      return $q(function (resolve) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function (position) {
            resolve({ lat: position.coords.latitude, lng: position.coords.longitude })
          }, function (err) {
            requestCustomerLocationAfterFail(err, resolve)
          }, { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true })
        } else {
          requestCustomerLocationAfterFail(new Error('navigator.geolocation not supported'), resolve)
        }
      })
    }

    function requestCustomerLocationAfterFail (err, resolve) {
      jQuery.getJSON('https://location.services.mozilla.com/v1/geolocate?key=test', function (response) {
        resolve({ lat: response.location.lat, lng: response.location.lng })
      })
    }

    function translateAddressOrZipToLocation (zipOrCity) {
      return $q(function (resolve, reject) {
        var geocoder = new google.maps.Geocoder()
        var req = { address: zipOrCity.replace(new RegExp(' ', 'g'), '+') }
        if (service.country) req.componentRestrictions = { country: service.country }
        geocoder.geocode(req, function (response, status) {
          if (status !== google.maps.GeocoderStatus.OK) {
            reject()
            return
          }

          var results = response.map(function (res) {
            return {
              googleResult: res,
              lng: res.geometry.location.lng(),
              lat: res.geometry.location.lat()
            }
          })

          resolve(results)
        })
      })
    }

    function getDistanceInKM (lat1, lon1, lat2, lon2) {
      var R = 6378.137 // Radius of earth in KM
      var dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180
      var dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180
      var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      var d = R * c
      return d
    }

    /**
     * Retourne un code postal, une ville ou une adresse en fonction
     * de geocodingField (qui vient de supplier.geocoding_field).
     */
    function extractGeocodingFieldFromGoogleResult (googleResult, geocodingField) {
      if (geocodingField === 'postaladdress') {
        // toute adresse nous va => pas de traitement particulier
        return googleResult.formatted_address
      }

      if (geocodingField === 'zipcode') var requiredType = 'postal_code'
      else if (geocodingField === 'city') requiredType = 'locality'
      else throw new Error('Unsupported supplier.geocoding_field = ' + geocodingField)

      // la première (donc la plus précise dans l'ordre de google map) composante de l'adresse
      // qui répond aux attentes de geocodingField
      var addrComponent = googleResult.address_components.find(function (addrComponent) {
        return addrComponent.types.indexOf(requiredType) !== -1
      })

      if (typeof addrComponent === 'undefined') {
        throw new Error('Invalid address : does not contains a valid ' + geocodingField)
      }

      return addrComponent.long_name
    }
  }
})()
