/* global angular */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('datetimePicker', {
      templateUrl: 'search-bar/datetime-picker.component.html',
      bindings: {
        isDayDisabled: '<',
        isHourDisabled: '<',
        dateChange: '<'
      },
      controller: DatetimePicker,
      controllerAs: 'vm'
    })

  DatetimePicker.$inject = []
  function DatetimePicker () {
    var vm = this

    vm.dateTime = null
    vm.currentYearAndMonth = null
    vm.$onInit = $onInit
    vm.clickOnDateCell = clickOnDateCell
    vm.clickOnHourCell = clickOnHourCell
    vm.formatCurrentMonth = formatCurrentMonth
    vm.buildGrid = buildGrid

    function $onInit () {
      var date = new Date()
      vm.currentYearAndMonth = moment().date(1).hour(0).minute(0).second(0).millisecond(0)
      vm.currentMonth = date.getMonth()
      buildGrid()
    }

    function formatCurrentMonth () {
      return vm.currentYearAndMonth.format('MMMM YYYY').toUpperCase()
    }

    function buildGrid () {
      var date = vm.currentYearAndMonth.clone()
      var nextMonth = date.clone().add(1, 'month')
      
      // grille dont les colonnes correspondent aux jours, les lignes aux semaines et les cellules aux dates
      var grid = [[null, null, null, null, null, null, null]]
      
      while (date.valueOf() < nextMonth.valueOf()) {
        var lastRow = grid[grid.length - 1]
      
        // 0 pour lundi, 6 pour dimanche
        var dayOfWeek = (date.day() + 6) % 7
      
        lastRow[dayOfWeek] = { date: date.clone(), isDisabled: vm.isDayDisabled(date) }
        if (dayOfWeek === 6) grid.push(lastRow = [null, null, null, null, null, null, null])
        date.add(1, 'day')
      }
    
      vm.grid = grid
    }

    function clickOnDateCell (cell) {
      if (cell.isDisabled) return

      for (var i = 0; i < vm.grid.length; i++) {
        for (var j = 0; j < vm.grid[i].length; j++) {
          if (vm.grid[i][j]) vm.grid[i][j].isSelected = false
        }
      }

      cell.isSelected = true
      vm.dateTime = cell.date.clone()
    }

    function clickOnHourCell (hour) {
      vm.dateTime.hour(hour)
      vm.dateChange(vm.dateTime)
    }
  }
})()
