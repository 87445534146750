/* global angular */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('marketplaceSearchBarSuppliers', {
      templateUrl: 'search-bar/marketplace-search-bar-suppliers.component.html',
      bindings: {
        ossMenuSelector: '<',
        availableSuppliersRefs: '=',
        error: '=',
        marketplace: '<',
        suppliers: '<',
        googleLocation: '<',
        location: '<',
        startDate: '<',
        endDate: '<',
        selectedService: '<',
        lastSubmitFailed: '=',
        currentInput: '=',
        isInFooter: '<',
        loading: '=',
        castingType: '<',
        participantsNumber: '<',
        ossUrl: '<'
      },
      controller: MarketplaceSearchBarSuppliersController,
      controllerAs: 'vm'
    })

  MarketplaceSearchBarSuppliersController.$inject = ['OSS_TRANSLATIONS_DATA', 'GeolocationServiceFactory', '$http', 'MarketplaceSearchBarSubmitFactory']
  function MarketplaceSearchBarSuppliersController (OSS_TRANSLATIONS_DATA, GeolocationServiceFactory, $http, MarketplaceSearchBarSubmitFactory) {
    var vm = this

    vm.searchSuppliers = searchSuppliers
    vm.focusedSupplier = null
    vm.selectedSupplier = null
    vm.translations = OSS_TRANSLATIONS_DATA

    function canSubmit () {
      if (!vm.googleLocation) return false

      if (!vm.startDate) {
        vm.currentInput = 'start'
        return false
      }

      if (!vm.endDate && vm.selectedService.durations) {
        vm.currentInput = 'end'
        return false
      }

      return true
    }

    function searchSuppliers () {
      vm.lastSubmitFailed = !canSubmit()
      if (vm.lastSubmitFailed) return
      vm.loading = true

      var url = '/wp-content/plugins/oss-wp/oss-wp-proxy.php'
      var body = {
        remoteProcedure: 'get_marketplace_suppliers_available',
        marketplaceId: vm.marketplace.id,
        body: getSearchSuppliersBody()
      }

      $http.post(url, body)
        .catch(function (err) {
          // when an error occures on tha endpoint, we simply redirect to OSS
          var url = vm.ossUrl + MarketplaceSearchBarSubmitFactory.getBookingQueryParams(
            vm.marketplace, vm.castingType, vm.location, vm.selectedService, vm.startDate, vm.endDate, vm.participantsNumber
          )

          window.location.href = url
          throw err
        })
        .then(function (response) {
          vm.availableSuppliersRefs = Object.keys(response.data.suppliers)
            .map(function (_) {
              var supplierId = parseInt(_)
              return vm.suppliers.find(function (supplier) { return supplier.id === supplierId })
            })
            .filter(function (_) { return !!_ })

          if (!vm.availableSuppliersRefs.length) {
            vm.error = { type: 'No result', message: 'No employee can take this opportunity' }

            window.location.href = vm.ossUrl + MarketplaceSearchBarSubmitFactory.getBookingQueryParams(
              vm.marketplace, vm.castingType, vm.location, vm.selectedService, vm.startDate, vm.endDate, vm.participantsNumber
            )
          }
        })
        .catch(function (err) { console.log(err) })
        .then(function () { vm.loading = false })
    }

    function getSearchSuppliersBody () {
      return {
        casting: {
          city: {
            city: GeolocationServiceFactory.extractGeocodingFieldFromGoogleResult(vm.googleLocation, 'city'),
            postalCode: GeolocationServiceFactory.extractGeocodingFieldFromGoogleResult(vm.googleLocation, 'zipcode')
          },
          offered_service_id: vm.selectedService.id,
          start_date_str: vm.startDate.format(),
          service_duration: vm.endDate.diff(vm.startDate, 'hour', true)
        }
      }
    }
  }
})()
