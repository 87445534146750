/* global angular jQuery */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('iframeOrRedirect', {
      templateUrl: 'iframe/iframe-or-redirect.component.html',
      bindings: {
        url: '<',
        ios: '<',
        ossMenuSelector: '<'
      },
      controller: IframeController,
      controllerAs: 'vm'
    })

  IframeController.$inject = ['OSS_TRANSLATIONS_DATA', '$scope']
  function IframeController (OSS_TRANSLATIONS_DATA, $scope) {
    var vm = this

    vm.style = {}
    vm.$onInit = $onInit
    vm.translations = OSS_TRANSLATIONS_DATA

    function $onInit () {
      if (!vm.ios) document.body.className += ' no-scroll-body'
      watchResize()
    }

    function watchResize () {
      window.addEventListener('resize', function () {
        $scope.$apply(onScreenWidthChange)
      })

      onScreenWidthChange()
    }

    function onScreenWidthChange () {
      if (document.documentElement.clientWidth < 710) {
        var menuToShow = jQuery(vm.ossMenuSelector)
        var top = menuToShow.offset().top + menuToShow.outerHeight()
        vm.style = {
          top: top + 'px',
          height: 'calc(100% - ' + top + 'px)'
        }
        jQuery(document).scrollTop(0)
        jQuery(document.body).scrollTop(0)
      } else {
        vm.style = {}
      }
    }
  }
})()
