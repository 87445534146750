/* global angular */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('ratingScore', {
      templateUrl: 'ratings/rating-score.component.html',
      bindings: { score: '<', showI18nScore: '<' },
      controller: RatingScoreController,
      controllerAs: 'vm'
    })

  RatingScoreController.$inject = ['OSS_TRANSLATIONS_DATA']
  function RatingScoreController (OSS_TRANSLATIONS_DATA) {
    var vm = this

    vm.classes = [1, 2, 3, 4, 5]
    vm.getReviewTranslation = getReviewTranslation
    vm.getMark = getMark
    vm.getCount = getCount
    vm.getMarkI18n = getMarkI18n

    function getReviewTranslation () {
      return vm.score.count > 1 ? OSS_TRANSLATIONS_DATA.reviews : OSS_TRANSLATIONS_DATA.review
    }

    function getMark () {
      return typeof vm.score === 'object' ? vm.score.avg : vm.score
    }

    function getCount () {
      return typeof vm.score === 'object' ? vm.score.count : null
    }

    function getMarkI18n () {
      switch (getMark()) {
        case 1: return OSS_TRANSLATIONS_DATA['Disappointing']
        case 2: return OSS_TRANSLATIONS_DATA['Ungood']
        case 3: return OSS_TRANSLATIONS_DATA['Good']
        case 4: return OSS_TRANSLATIONS_DATA['Very good']
        case 5: return OSS_TRANSLATIONS_DATA['Excellent']
      }
    }
  }
})()
