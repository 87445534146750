/* global angular */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('avatar', {
      templateUrl: 'employees/avatar.component.html',
      bindings: { employee: '<' },
      controller: AvatarController,
      controllerAs: 'vm'
    })

  AvatarController.$inject = []
  function AvatarController () {}
})()
