/* global angular jQuery */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .directive('bindHeight', function () {
      return {
        restrict: 'A',
        link: function (jQueryscope, element, attrs) {
          element.bind('DOMSubtreeModified', function () {
            var value = jQuery(this).attr('bind-height')

            function setSharedHeight () {
              if (value === 'null') return

              jQuery('[bind-height]').each(function () {
                var elems = jQuery("[bind-height='" + value + "']")
                var heights = elems.toArray().map(function (elem) {
                  return jQuery(elem).height()
                })

                if (Math.max.apply(this, heights) > Math.min.apply(this, heights)) {
                  jQuery(this).css('min-height', Math.max.apply(this, heights) + 'px')
                }
              })
            }

            setTimeout(setSharedHeight)
            setTimeout(setSharedHeight, 250)
            setTimeout(setSharedHeight, 1000)
          })
        }
      }
    })
})()
