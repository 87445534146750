/* global angular */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('listRatings', {
      templateUrl: 'ratings/list-ratings.component.html',
      bindings: { ratings: '<', limit: '<' },
      controller: ListRatingsController,
      controllerAs: 'vm'
    })

  ListRatingsController.$inject = ['OSS_TRANSLATIONS_DATA']
  function ListRatingsController (OSS_TRANSLATIONS_DATA) {
    var vm = this

    vm.translations = OSS_TRANSLATIONS_DATA
    vm.$onInit = $onInit

    function $onInit () {
      if (typeof vm.ratings === 'string' && vm.ratings.startsWith('json:')) {
        vm.ratings = JSON.parse(jQuery('script[id="' + vm.ratings + '"]').text())
      }
    }
  }
})()
