/* global angular jQuery */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('searchBar', {
      templateUrl: 'search-bar/search-bar.component.html',
      bindings: {
        ossUrl: '<',
        supplier: '<',
        employees: '<',
        allowedServiceId: '<',
        previewEmployees: '<',
        isInShowcase: '<',
        newTab: '<',
        ossMenuSelector: '<',
        selectCastingType: '<',
        defaultCastingType: '<',
        fullyDeployed: '<',
        preloadEmployeesList: '<'
      },
      controller: SearchBarController,
      controllerAs: 'vm'
    })

  SearchBarController.$inject = ['GeolocationServiceFactory', '$rootScope', '$scope', '$http', '$element']
  function SearchBarController (GeolocationServiceFactory, $rootScope, $scope, $http, $element) {
    var vm = this

    vm.loading = false
    vm.redirectUrl = null

    // <search-bar-recurring>
    vm.castingType = null

    // <search-bar-location>
    vm.location = ''
    vm.googleLocation = null
    vm.isServiceAvailableForLocation = undefined
    vm.lastSubmitFailed = false

    // <search-bar-categories>
    vm.availableCategoriesAtLocation = null
    vm.selectedCategory = null

    // <search-bar-service>
    vm.availableServicesAtLocation = null
    vm.selectedService = null

    // <search-bar-participants-number>
    vm.participantsNumber = null

    // <search-bar-date-and-duration>
    vm.currentInput = null
    vm.startDate = null
    vm.endDate = null

    // <search-bar-employees>
    vm.availableEmployeesRefs = null

    // <search-bar-error>
    vm.error = null

    vm.$onInit = $onInit
    vm.onLocationSelected = onLocationSelected
    vm.isInFooter = isInFooter
    vm.nextInputStep = nextInputStep
    vm.canPreviewEmployees = canPreviewEmployees

    // si toute variable est mise à jour, on annule les erreurs et les résultats des requêtes ajax
    $scope.$watch(function () { return vm.castingType }, onCastingTypeChanged)
    $scope.$watch(function () { return vm.location }, clearErrorAndAjax)
    $scope.$watch(function () { return vm.selectedService }, clearErrorAndAjax)
    $scope.$watch(function () { return vm.startDate }, clearErrorAndAjax)
    $scope.$watch(function () { return vm.endDate }, clearErrorAndAjax)
    $scope.$watch(function () { return vm.participantsNumber }, clearErrorAndAjax)
    $scope.$watch(function () { return vm.availableEmployeesRefs }, handleShowcaseEmployeesList)

    function $onInit () {
      if (typeof vm.supplier === 'string' && vm.supplier.startsWith('json:')) {
        vm.supplier = JSON.parse(jQuery('script[id="' + vm.supplier + '"]').text())
      }

      if (typeof vm.employees === 'string' && vm.employees.startsWith('json:')) {
        vm.employees = JSON.parse(jQuery('script[id="' + vm.employees + '"]').text())
      }

      vm.castingType = vm.defaultCastingType

      jQuery(window).click(function () {
        $scope.$apply(function () { vm.currentInput = null })
      })

      GeolocationServiceFactory.country = vm.supplier.country
      if (vm.fullyDeployed) setServicesAndCategories(vm.supplier.offered_services)
    }

    function clearErrorAndAjax () {
      vm.error = null
      vm.availableEmployeesRefs = null
    }

    function setServicesAndCategories (services) {
      var allowedServices = services.filter(isServiceAllowed)

      if (allowedServices.length === 0) {
        vm.error = { type: 'No result', message: 'Service not provided at your location.' }
        vm.availableCategoriesAtLocation = null
        vm.availableServicesAtLocation = null
      } else {
        vm.error = null

        vm.availableServicesAtLocation = allowedServices.slice()
        vm.availableCategoriesAtLocation = vm.supplier.categories.filter(function (category) {
          var servicesInCategory = getServicesInCategoryTree(category)
          return allowedServices.find(function (service) {
            return servicesInCategory.find(function (serviceInCategory) { return serviceInCategory.id === service.id })
          })
        })

        // reset vm.selectedCategory if not in vm.availableCategoriesAtLocation
        if (vm.selectedCategory && !vm.availableCategoriesAtLocation.find(function (c) { return c.id === vm.selectedCategory.id })) {
          vm.selectedCategory = null
        }

        // reset vm.selectedService if not in vm.availableServicesAtLocation
        if (vm.selectedService && !vm.availableServicesAtLocation.find(function (s) { return s.id === vm.selectedService.id })) {
          vm.selectedService = null
        }
      }
    }

    function isServiceAllowed (service) {
      if (!service[vm.castingType]) return false
      if (!service.validated) return false
      if (typeof vm.allowedServiceId === 'number') return service.id === vm.allowedServiceId
      if (vm.allowedServiceId === '*') return true

      return !!vm.allowedServiceId.split(',')
        .find(function (s) { return parseInt(s.trim()) === service.id })
    }

    /** Ouvre la prochaine modale de saisie compte tenu des fonctionnalités actives et de l'étape actuelle */
    function nextInputStep () {
      vm.currentInput = (function (current) {
        if (current === 'location') {
          if (vm.availableServicesAtLocation && !vm.selectedService) return 'service'
          if (vm.selectedService && vm.selectedService.require_participants_number) return 'participants-number'
          return 'start'
        } else if (current === 'service') {
          if (vm.selectedService && vm.selectedService.require_participants_number) return 'participants-number'
          return 'start'
        } else if (current === 'participants-number') {
          return 'start'
        } else if (current === 'start') {
          return 'end'
        }

        return null
      })(vm.currentInput)
    }

    function onLocationSelected (location, googleLocation) {
      vm.loading = true

      handleShowcaseEmployeesList()

      try {
        var url = '/wp-content/plugins/oss-wp/oss-wp-proxy.php'

        var body = {
          remoteProcedure: 'get_services',
          supplierId: vm.supplier.id,
          query: 'zipcode=' + encodeURIComponent(
            GeolocationServiceFactory.extractGeocodingFieldFromGoogleResult(googleLocation, 'zipcode')
          )
        }

        vm.location = location
        vm.googleLocation = googleLocation

        $http.post(url, body).then(function (response) {
          vm.loading = false
          var filteredServices = response.data.filter(isServiceAllowed).reduce(function (array, curr) {
            if (!array.find(function (service) { return service.id === curr.id })) array.push(curr)
            return array
          }, [])
  
          vm.currentInput = 'location'
          setServicesAndCategories(filteredServices)
          nextInputStep()
          handleShowcaseEmployeesList()
        })
      } catch (err) {
        vm.loading = false
        if (err.message.startsWith('Invalid address')) {
          vm.error = { type: 'No result', message: 'Unable to parse this address.' }
        } else {
          throw err
        }
      }
    }

    /**
     * Retourne `true` si l'élement est en bas de la page et que l'affichage des
     * inputs flottants posent problème (dépassent de la page par le bas et ne sont pas rendu totalement).
     */
    function isInFooter () {
      return (jQuery(document).height() - $element.offset().top) < 600
    }

    function handleShowcaseEmployeesList () {
      if (!vm.isInShowcase) return

      var employees = []
      
      if (vm.error) {
        employees = []
      } else if (vm.availableEmployeesRefs === null && vm.preloadEmployeesList) {
        employees = vm.employees
      } else if (vm.availableEmployeesRefs !== null) {
        employees = vm.availableEmployeesRefs.map(function (ref) { return ref.employee })
      }

      $rootScope.$broadcast('showcase.set.employees', {
        supplierId: vm.supplier.id,
        employees: employees
      })
    }
    
    function canPreviewEmployees () {
      return vm.previewEmployees && vm.castingType === 'casting_one_shot' && (!vm.selectedService || vm.selectedService.durations)
    }

    function onCastingTypeChanged () {
      if (vm.googleLocation) onLocationSelected(vm.location, vm.googleLocation)
      else if (vm.fullyDeployed) setServicesAndCategories(vm.supplier.offered_services)
      clearErrorAndAjax()
    }

    /** Retourne la liste des services de `rootCategory` et de ses catégories filles. */
    function getServicesInCategoryTree (rootCategory) {
      var children = vm.supplier.categories.filter(function (category) { return category.parent_category_id === rootCategory.id })
      var services = rootCategory.offered_services.slice()

      for (var i = 0; i < children.length; i++) {
        var childServices = getServicesInCategoryTree(children[i])
        for (var j = 0; j < childServices.length; j++) {
          if (!services.find(function (service) { return service.id === childServices[j].id })) {
            services.push(childServices[j])
          }
        }
      }

      return services
    }
  }
})()
