/* global angular */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('searchBarError', {
      templateUrl: 'search-bar/search-bar-error.component.html',
      bindings: {
        error: '<'
      },
      controller: SearchBarErrorController,
      controllerAs: 'vm'
    })

  SearchBarErrorController.$inject = ['OSS_TRANSLATIONS_DATA']
  function SearchBarErrorController (OSS_TRANSLATIONS_DATA) {
    var vm = this

    vm.translations = OSS_TRANSLATIONS_DATA
  }
})()
