/* global angular */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('searchBarLocation', {
      templateUrl: 'search-bar/search-bar-location.component.html',
      bindings: {
        geocodingField: '<',
        showAnyError: '<',
        onLocationSelected: '&',
        isInFooter: '<',
        loading: '='
      },
      controller: SearchBarLocationController,
      controllerAs: 'vm'
    })

  SearchBarLocationController.$inject = ['OSS_TRANSLATIONS_DATA', 'GeolocationServiceFactory']
  function SearchBarLocationController (OSS_TRANSLATIONS_DATA, GeolocationServiceFactory) {
    var vm = this

    vm.zipOrCitySearch = ''
    vm.locations = []
    vm.showLocations = false

    vm.onZipOrCitySearchChanged = onZipOrCitySearchChanged
    vm.selectLocationInList = selectLocationInList
    vm.getPlaceholder = getPlaceholder

    vm.translations = OSS_TRANSLATIONS_DATA

    function onZipOrCitySearchChanged () {
      vm.loading = true
      GeolocationServiceFactory.translateAddressOrZipToLocation(vm.zipOrCitySearch)
        .then(function (locations) {
          // on accepte que les propositions dont on peut extraire le bon vm.geocodingField.
          vm.locations = locations.filter(function (location) {
            try {
              GeolocationServiceFactory.extractGeocodingFieldFromGoogleResult(location.googleResult, vm.geocodingField)
              return true
            } catch (e) {
              return false
            }
          })
          vm.showLocations = true
        })
        .catch(function () { vm.locations = [] })
        .then(function () { vm.loading = false })
    }

    function selectLocationInList (location) {
      GeolocationServiceFactory.setLocation(location.lat, location.lng, 'input', location.googleResult)
      vm.zipOrCitySearch = GeolocationServiceFactory.extractGeocodingFieldFromGoogleResult(location.googleResult, vm.geocodingField)
      vm.showLocations = false
      vm.onLocationSelected({ $location: vm.zipOrCitySearch, $googleLocation: location.googleResult })
    }

    function getPlaceholder () {
      var i18n = { postaladdress: 'Your address', city: 'Your city', zipcode: 'Your postal code' }
      return vm.translations[i18n[vm.geocodingField]]
    }
  }
})()
