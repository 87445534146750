/* global angular */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('searchBarLoading', {
      templateUrl: 'search-bar/search-bar-loading.component.html',
      bindings: {},
      controller: SearchBarLoadingController,
      controllerAs: 'vm'
    })

  SearchBarLoadingController.$inject = []
  function SearchBarLoadingController () {}
})()
