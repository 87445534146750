/* global angular */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('cardEmployee', {
      templateUrl: 'employees/card-employee.component.html',
      bindings: {
        employee: '<',
        bindHeightName: '<',
        isLarge: '<'
      },
      controller: CardEmployeeController,
      controllerAs: 'vm'
    })

  CardEmployeeController.$inject = ['OSS_TRANSLATIONS_DATA', 'GeolocationServiceFactory']
  function CardEmployeeController (OSS_TRANSLATIONS_DATA, GeolocationServiceFactory) {
    var vm = this

    vm.translations = OSS_TRANSLATIONS_DATA

    vm.canCalculateDistance = canCalculateDistance
    vm.getDistanceToLocation = getDistanceToLocation
    vm.$onInit = $onInit

    function $onInit () {
      vm.isLarge = !!vm.isLarge
    }

    function canCalculateDistance () {
      return vm.employee.lat &&
        vm.employee.lng &&
        GeolocationServiceFactory.customerLatitude &&
        GeolocationServiceFactory.customerLongitude
    }

    function getDistanceToLocation () {
      if (!canCalculateDistance()) return null

      return Math.round(GeolocationServiceFactory.getDistanceInKM(
        GeolocationServiceFactory.customerLatitude,
        GeolocationServiceFactory.customerLongitude,
        vm.employee.lat,
        vm.employee.lng
      ) * 10) / 10
    }
  }
})()
