/* global angular jQuery */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('listEmployees', {
      templateUrl: 'employees/list-employees.component.html',
      bindings: {
        ossMenuSelector: '<',
        data: '<'
      },
      controller: ListEmployeesController,
      controllerAs: 'vm'
    })

  ListEmployeesController.$inject = ['OSS_TRANSLATIONS_DATA', 'GeolocationServiceFactory', '$scope']
  function ListEmployeesController (OSS_TRANSLATIONS_DATA, GeolocationServiceFactory, $scope) {
    var vm = this

    vm.translations = OSS_TRANSLATIONS_DATA

    vm.ratingFilterOptions = [{ text: OSS_TRANSLATIONS_DATA['Disabled filter'], value: 0 }].concat(
      [1, 2, 3, 4].map(function (n) {
        return { text: n + ' ' + '★'.repeat(n), value: n }
      })
    )

    vm.distanceFilterOptions = [
      { text: OSS_TRANSLATIONS_DATA['Disabled filter'], value: 100000 },
      { text: 2 + ' ' + OSS_TRANSLATIONS_DATA['km'], value: 2 },
      { text: 5 + ' ' + OSS_TRANSLATIONS_DATA['km'], value: 5 },
      { text: 10 + ' ' + OSS_TRANSLATIONS_DATA['km'], value: 10 },
      { text: 25 + ' ' + OSS_TRANSLATIONS_DATA['km'], value: 25 }
    ]

    vm.showAll = false
    vm.selectedEmployee = null
    vm.filterMinRating = 0
    vm.filterMaxDistanceKm = 100000
    vm.renderableEmployees = []
    vm.ratingsValues = [1, 2, 3, 4]

    vm.$onInit = $onInit
    vm.getLimitTo = getLimitTo
    vm.onFilterCriteriaUpdated = onFilterCriteriaUpdated

    $scope.$watch(function () {
      return GeolocationServiceFactory.version
    }, function () {
      if (GeolocationServiceFactory.isLocationValid()) onFilterCriteriaUpdated()
    })

    function $onInit () {
      if (typeof vm.data === 'string' && vm.data.startsWith('json:')) {
        vm.data = JSON.parse(jQuery('script[id="' + vm.data + '"]').text())
      }

      vm.data.employees.sort(function (employeeA, employeeB) {
        return ((employeeB.avgRating && employeeB.avgRating.avg) || 0) - ((employeeA.avgRating && employeeA.avgRating.avg) || 0)
      })
      onFilterCriteriaUpdated()

      if (vm.data.args.isInShowcase) {
        $scope.$on('showcase.set.employees', function (ev, data) {
          if (vm.data.args.supplierId === data.supplierId) {
            vm.data.employees = data.employees
            onFilterCriteriaUpdated()
          }
        })
      }
    }

    function getLimitTo () {
      return vm.showAll ? Infinity : vm.data.args.listSize
    }

    function onFilterCriteriaUpdated () {
      vm.renderableEmployees = vm.data.employees.filter(function (employee) {
        if (vm.filterMinRating >= 1) {
          if (!employee.avgRating || employee.avgRating.avg < vm.filterMinRating) return false
        }

        if (vm.filterMaxDistanceKm < 100000 && GeolocationServiceFactory.isLocationValid()) {
          if (!employee.lat || !employee.lng) return false

          var distance = GeolocationServiceFactory.getDistanceInKM(
            GeolocationServiceFactory.customerLatitude,
            GeolocationServiceFactory.customerLongitude,
            employee.lat,
            employee.lng
          )

          if (distance > vm.filterMaxDistanceKm) return false
        }

        return true
      })

      if (vm.data.args.isInShowcase && GeolocationServiceFactory.customerLatitude && GeolocationServiceFactory.customerLongitude) {
        vm.renderableEmployees.sort(function (employeeA, employeeB) {
          return getDistanceOfEmployeeForSort(employeeA) - getDistanceOfEmployeeForSort(employeeB)
        })
      }
    }

    function getDistanceOfEmployeeForSort (employee) {
      if (!employee.lat || !employee.lng) return 10000

      return GeolocationServiceFactory.getDistanceInKM(
        GeolocationServiceFactory.customerLatitude,
        GeolocationServiceFactory.customerLongitude,
        employee.lat,
        employee.lng
      )
    }
  }
})()
