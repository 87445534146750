/* global angular */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('searchBarCategory', {
      templateUrl: 'search-bar/search-bar-category.component.html',
      bindings: {
        categories: '<',
        selectedCategory: '=',
        currentInput: '=',
        nextInputStep: '&',
        isInFooter: '<'
      },
      controller: SearchBarCategoriesController,
      controllerAs: 'vm'
    })

  SearchBarCategoriesController.$inject = ['OSS_TRANSLATIONS_DATA']
  function SearchBarCategoriesController (OSS_TRANSLATIONS_DATA) {
    var vm = this

    vm.selectCategory = selectCategory
    vm.getIconUrl = getIconUrl
    vm.translations = OSS_TRANSLATIONS_DATA

    function selectCategory (category) {
      vm.selectedCategory = category
      vm.nextInputStep()
    }

    function getIconUrl () {
      if (vm.selectedCategory) return vm.selectedCategory.logo.url
      return "/wp-content/plugins/oss-wp/public/img/folder.png"
    }
  }
})()
