/* global angular */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('mapLocationBar', {
      templateUrl: 'employees/map-location-bar.component.html',
      bindings: { id: '<' },
      controller: MapLocationBarController,
      controllerAs: 'vm'
    })

  MapLocationBarController.$inject = ['OSS_TRANSLATIONS_DATA', 'GeolocationServiceFactory', '$scope']
  function MapLocationBarController (OSS_TRANSLATIONS_DATA, GeolocationServiceFactory, $scope) {
    var vm = this

    vm.zipOrCitySearch = null
    vm.locations = []

    vm.translations = OSS_TRANSLATIONS_DATA
    vm.refreshLocation = GeolocationServiceFactory.refreshLocation
    vm.onZipOrCitySearchChanged = onZipOrCitySearchChanged
    vm.submit = submit

    $scope.$watch(function () {
      return GeolocationServiceFactory.googleResult
    }, function () {
      if (GeolocationServiceFactory.googleResult) {
        vm.zipOrCitySearch = GeolocationServiceFactory.googleResult.formatted_address
      }
    })

    function onZipOrCitySearchChanged () {
      GeolocationServiceFactory.translateAddressOrZipToLocation(vm.zipOrCitySearch)
        .then(function (locations) { vm.locations = locations })
        .catch(function () { vm.locations = [] })
    }

    function submit (location) {
      vm.locations = []
      GeolocationServiceFactory.setLocation(location.lat, location.lng, 'input', location.googleResult)
    }
  }
})()
