/* global angular */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .factory('MarketplaceSearchBarSubmitFactory', MarketplaceSearchBarSubmitFactory)

  MarketplaceSearchBarSubmitFactory.$inject = []

  function MarketplaceSearchBarSubmitFactory () {
    var service = {
      getBookingQueryParams: getBookingQueryParams
    }

    return service

    function getBookingQueryParams (marketplace, castingType, location, selectedService, startDate, endDate, participantsNumber) {
      var query = {}
      query['marketplace_id'] = marketplace.id
      query['casting[casting_type]'] = castingType
      query['casting[' + marketplace.field_for_geocoding + ']'] = location
      query['casting[offered_service_id]'] = selectedService.id

      if (castingType === 'casting_one_shot') {
        query['casting[start_date]'] = startDate.format()
  
        if (endDate) { // peut ne pas être définit dans une situation pourtant valide : le cas des forfaits...
          query['casting[service_duration]'] = endDate.diff(startDate, 'hour', true)
        }
      }

      if (participantsNumber) {
        query['casting[participants_number]'] = participantsNumber
      }

      var queryString = Object.keys(query).map(function (key) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
      }).join('&')

      return queryString
    }
  }
})()
