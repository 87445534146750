/* global angular moment */

; (function () {
  'use strict'

  var userLang = navigator.language || navigator.userLanguage
  moment.locale(userLang.substring(0, 2))

  var modules = ['datePicker']
  var app = angular.module('ng-app-oss', modules)
  app.constant('OSS_TRANSLATIONS_DATA', window.ossTranslations)

  ConfigController.$inject = ['$sceProvider']
  function ConfigController ($sceProvider) {
    $sceProvider.enabled(false)
  }
  app.config(ConfigController)
})()
