/* global angular */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('rating', {
      templateUrl: 'ratings/rating.component.html',
      bindings: { rating: '<' },
      controller: RatingController,
      controllerAs: 'vm'
    })

  RatingController.$inject = ['OSS_TRANSLATIONS_DATA']
  function RatingController (OSS_TRANSLATIONS_DATA) {
    var vm = this

    vm.translations = OSS_TRANSLATIONS_DATA
    vm.getDescription = getDescription

    function getDescription () {
      return vm.translations["%'s review about %'s performance from %"]
        .replace('%', '<b class="main-supplier-color">' + vm.rating.customer_name + '</b>')
        .replace('%', '<b class="main-supplier-color">' + vm.rating.employee_name + '</b>')
        .replace('%', '<b class="main-supplier-color">' + vm.rating.market_name + '</b>')
        + ' - ' +
        moment(vm.rating['created_at']).format('MMMM YYYY')
    }
  }
})()
