/* global angular jQuery */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('modalSupplier', {
      templateUrl: 'suppliers/modal-supplier.component.html',
      bindings: {
        supplier: '<',
        args: '<',
        ossMenuSelector: '<',
        onClose: '&'
      },
      controller: ModalSupplierController,
      controllerAs: 'vm'
    })

  ModalSupplierController.$inject = ['OSS_TRANSLATIONS_DATA']
  function ModalSupplierController (OSS_TRANSLATIONS_DATA) {
    var vm = this

    vm.translations = OSS_TRANSLATIONS_DATA
    vm.maxRatingCount = 3

    vm.getModalMargin = getModalMargin
    vm.getBusinessHours = getBusinessHours

    vm.daysI18n = [
      vm.translations.Monday,
      vm.translations.Tuesday,
      vm.translations.Wednesday,
      vm.translations.Thursday,
      vm.translations.Friday,
      vm.translations.Saturday,
      vm.translations.Sunday
    ]

    function getModalMargin () {
      try {
        var menuToShow = jQuery(vm.ossMenuSelector)
        var menuTop = menuToShow.offset().top + menuToShow.outerHeight()
        var usedTop = Math.min(140, menuTop)
        return usedTop + 'px auto'
      } catch (e) {
        return '4em auto'
      }
    }

    function getBusinessHours (day) {
      if (vm.supplier.working_days.indexOf(day) === -1) return vm.translations['Closed']

      var opening = vm.supplier.opening_hours[day]
      var closing = vm.supplier.closing_hours[day]

      if ((!opening.length && !closing.length) || (opening === '00:00' && closing === '00:00')) return '24/24'
      return opening + ' - ' + closing
    }
  }
})()
