/* global angular moment */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('searchBarDateAndDuration', {
      templateUrl: 'search-bar/search-bar-date-and-duration.component.html',
      bindings: {
        service: '<',
        currentInput: '=',
        nextInputStep: '&',
        startDate: '=',
        endDate: '=',
        isInFooter: '<'
      },
      controller: SearchBarDateAndDurationController,
      controllerAs: 'vm'
    })

  SearchBarDateAndDurationController.$inject = ['OSS_TRANSLATIONS_DATA', '$scope']
  function SearchBarDateAndDurationController (OSS_TRANSLATIONS_DATA, $scope) {
    var vm = this

    vm.$onInit = $onInit
    vm.onStartDateSelected = onStartDateSelected
    vm.isHourDisabled = isHourDisabled
    vm.isDayDisabled = isDayDisabled
    vm.hasDurations = hasDurations

    vm.translations = OSS_TRANSLATIONS_DATA

    $scope.$watch(function () {
      return vm.service.id
    }, function () {
      setMinStartDate()
      clearDurationIfInvalid()
    })

    function $onInit () {
      setMinStartDate()
    }

    function setMinStartDate () {
      vm.minStartDate = moment().add(getWaitingPeriod(), 'hour')
      if (!vm.startDate || vm.startDate.diff(vm.minStartDate) < 0) {
        vm.startDate = null
        vm.endDate = null
      }
    }

    function onStartDateSelected (newValue) {
      vm.nextInputStep()
      vm.startDate = newValue
      vm.endDate = null
    }

    function clearDurationIfInvalid () {
      if (vm.startDate && vm.endDate) {
        var durationInHours = vm.endDate.diff(vm.startDate, 'hour', true)
        var isValid = vm.service.durations.find(function (duration) {
          return duration.duration === durationInHours
        })

        if (!isValid) vm.endDate = null
      }
    }

    function getWaitingPeriod () {
      var extraTime = vm.service.casting_mode === 'direct' ? 0 : vm.service.waiting_hours_period
      return Math.max(vm.service.waiting_order_hours_period, extraTime)
    }

    function isHourDisabled (date) {
      if (date.valueOf() < Date.now() + getWaitingPeriod() * 3600 * 1000) return true
      var dayName = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'][date.day()]
      if (!vm.service.timeslots[dayName]) return true

      for (var period in vm.service.timeslots[dayName]) {
        if (vm.service.timeslots[dayName][period].find(function (slot) { return slot === date.hours() + 'h' })) return false
      }

      return true
    }

    function isDayDisabled (date) {
      if (date.valueOf() < moment().hour(0).minute(0).second(0).millisecond(0).valueOf() + getWaitingPeriod() * 3600 * 1000) return true
      if (!vm.service.timespan.days.find(function (day) { return day.day_id === date.day() })) return true
      return false
    }

    function hasDurations () {
      return vm.service.durations && vm.service.durations.length
    }
  }
})()
