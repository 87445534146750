/* global angular */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('cardSupplier', {
      templateUrl: 'suppliers/card-supplier.component.html',
      bindings: {
        supplier: '<',
        bindHeightName: '<',
        isLarge: '<'
      },
      controller: CardSupplierController,
      controllerAs: 'vm'
    })

  CardSupplierController.$inject = ['OSS_TRANSLATIONS_DATA']
  function CardSupplierController (OSS_TRANSLATIONS_DATA) {
    var vm = this

    vm.translations = OSS_TRANSLATIONS_DATA
    vm.$onInit = $onInit

    function $onInit () {
      vm.isLarge = !!vm.isLarge
    }
  }
})()
