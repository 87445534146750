/* global angular */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('searchBarEmployees', {
      templateUrl: 'search-bar/search-bar-employees.component.html',
      bindings: {
        ossMenuSelector: '<',
        availableEmployeesRefs: '=',
        error: '=',
        supplier: '<',
        employees: '<',
        googleLocation: '<',
        location: '<',
        startDate: '<',
        endDate: '<',
        selectedService: '<',
        lastSubmitFailed: '=',
        currentInput: '=',
        isInFooter: '<',
        loading: '=',
        castingType: '<',
        participantsNumber: '<',
        ossUrl: '<'
      },
      controller: SearchBarEmployeesController,
      controllerAs: 'vm'
    })

  SearchBarEmployeesController.$inject = ['OSS_TRANSLATIONS_DATA', 'GeolocationServiceFactory', 'SearchBarSubmitFactory', '$http']
  function SearchBarEmployeesController (OSS_TRANSLATIONS_DATA, GeolocationServiceFactory, SearchBarSubmitFactory, $http) {
    var vm = this

    vm.searchEmployees = searchEmployees
    vm.focusedEmployee = null
    vm.selectedEmployee = null
    vm.translations = OSS_TRANSLATIONS_DATA

    function canSubmit () {
      if (!vm.googleLocation) return false

      if (!vm.startDate) {
        vm.currentInput = 'start'
        return false
      }

      if (!vm.endDate && vm.selectedService.durations) {
        vm.currentInput = 'end'
        return false
      }

      return true
    }

    function searchEmployees () {
      vm.lastSubmitFailed = !canSubmit()
      if (vm.lastSubmitFailed) return
      vm.loading = true

      var url = '/wp-content/plugins/oss-wp/oss-wp-proxy.php'
      var body = {
        remoteProcedure: 'get_employees_available',
        supplierId: vm.supplier.id,
        body: getSearchEmployeesBody()
      }

      $http.post(url, body)
        .catch(function (err) {
          // when an error occures on tha endpoint, we simply redirect to OSS
          var url = vm.ossUrl + SearchBarSubmitFactory.getBookingQueryParams(
            vm.supplier, vm.castingType, vm.location, vm.selectedService, vm.startDate, vm.endDate, vm.participantsNumber
          )

          window.location.href = url
          throw err
        })
        .then(function (response) {
          vm.availableEmployeesRefs = response.data.employees

          if (vm.availableEmployeesRefs && vm.availableEmployeesRefs.length) {
            vm.availableEmployeesRefs = vm.availableEmployeesRefs.map(function (ref) {
              ref.employee = vm.employees.find(function (e) { return e.id === ref.employee_id })
              return ref
            }).filter(function (ref) {
              return !!ref.employee
            })
          } else {
            vm.error = { type: 'No result', message: 'No employee can take this opportunity' }

            window.location.href = vm.ossUrl + SearchBarSubmitFactory.getBookingQueryParams(
              vm.supplier, vm.castingType, vm.location, vm.selectedService, vm.startDate, vm.endDate, vm.participantsNumber
            )
          }
        })
        .catch(function (err) { console.log(err) })
        .then(function () { vm.loading = false })
    }

    function getSearchEmployeesBody () {
      return {
        casting: {
          city: {
            city: GeolocationServiceFactory.extractGeocodingFieldFromGoogleResult(vm.googleLocation, 'city'),
            postalCode: GeolocationServiceFactory.extractGeocodingFieldFromGoogleResult(vm.googleLocation, 'zipcode')
          },
          offered_service_id: vm.selectedService.id,
          start_date_str: vm.startDate.format(),
          service_duration: vm.endDate.diff(vm.startDate, 'hour', true)
        }
      }
    }
  }
})()
