/* global angular */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('redirect', {
      templateUrl: 'iframe/redirect.component.html',
      bindings: {
        url: '<',
        onCancel: '&',
        onDone: '&'
      },
      controller: RedirectController,
      controllerAs: 'vm'
    })

  RedirectController.$inject = ['OSS_TRANSLATIONS_DATA']
  function RedirectController (OSS_TRANSLATIONS_DATA) {
    var vm = this

    vm.redirectTimeoutId = null

    vm.$onInit = $onInit
    vm.$onDestroy = $onDestroy
    vm.cancelRedirect = cancelRedirect

    vm.translations = OSS_TRANSLATIONS_DATA

    function $onInit () {
      triggerRedirect()
    }

    function $onDestroy () {
      if (vm.redirectTimeoutId) cancelRedirect()
    }

    function cancelRedirect () {
      clearTimeout(vm.redirectTimeoutId)
      vm.redirectTimeoutId = null
      vm.onCancel()
    }

    function triggerRedirect () {
      if (vm.redirectTimeoutId) return
      vm.redirectTimeoutId = setTimeout(function () {
        document.location.href = vm.url
      }, 5000)
    }
  }
})()
