/* global angular */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('searchBarSubmitButton', {
      templateUrl: 'search-bar/search-bar-submit-button.component.html',
      bindings: {
        ossUrl: '<',
        googleLocation: '<',
        loading: '<',
        location: '<',
        startDate: '<',
        endDate: '<',
        selectedService: '<',
        supplier: '<',
        participantsNumber: '<',
        newTab: '<',
        castingType: '<',
        lastSubmitFailed: '=',
        currentInput: '=',
        redirectUrl: '='
      },
      controller: SearchBarSubmitController,
      controllerAs: 'vm'
    })

  SearchBarSubmitController.$inject = ['OSS_TRANSLATIONS_DATA', 'SearchBarSubmitFactory']
  function SearchBarSubmitController (OSS_TRANSLATIONS_DATA, SearchBarSubmitFactory) {
    var vm = this

    vm.book = book
    vm.translations = OSS_TRANSLATIONS_DATA

    function canSubmit () {
      if (!vm.location) return false

      if (vm.castingType === 'casting_one_shot') {
        if (!vm.startDate) {
          vm.currentInput = 'start'
          return false
        }
  
        if (!vm.endDate && vm.selectedService.durations && vm.selectedService.durations.length) {
          vm.currentInput = 'end'
          return false
        }
      }

      return true
    }

    function book () {
      vm.lastSubmitFailed = !canSubmit()
      if (vm.lastSubmitFailed) return

      var url = vm.ossUrl + SearchBarSubmitFactory.getBookingQueryParams(
        vm.supplier, vm.castingType, vm.location, vm.selectedService, vm.startDate, vm.endDate, vm.participantsNumber
      )

      if (vm.newTab) window.open(url, '_blank')
      else if (url.substring(0, 4) !== 'http') window.location.href = url
      else vm.redirectUrl = url
    }
  }
})()
