/* global angular */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('searchBarRecurring', {
      templateUrl: 'search-bar/search-bar-recurring.component.html',
      bindings: {
        castingType: '='
      },
      controller: SearchBarRecurringController,
      controllerAs: 'vm'
    })

  SearchBarRecurringController.$inject = ['OSS_TRANSLATIONS_DATA']
  function SearchBarRecurringController (OSS_TRANSLATIONS_DATA) {
    var vm = this

    vm.translations = OSS_TRANSLATIONS_DATA
  }
})()
