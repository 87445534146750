/* global angular */

;(function () {
  'use strict'

  angular
    .module('ng-app-oss')
    .component('searchBarService', {
      templateUrl: 'search-bar/search-bar-service.component.html',
      bindings: {
        allowedServices: '<',
        selectedService: '=',
        currentInput: '=',
        nextInputStep: '&',
        isInFooter: '<'
      },
      controller: SearchBarServiceController,
      controllerAs: 'vm'
    })

  SearchBarServiceController.$inject = ['OSS_TRANSLATIONS_DATA']
  function SearchBarServiceController (OSS_TRANSLATIONS_DATA) {
    var vm = this

    vm.getShortServiceName = getShortServiceName
    vm.selectService = selectService
    vm.getCommonStartOfServicesName = getCommonStartOfServicesName
    vm.getDivergentEndOfServiceName = getDivergentEndOfServiceName
    vm.translations = OSS_TRANSLATIONS_DATA

    function selectService (service) {
      vm.selectedService = service
      vm.nextInputStep()
    }

    function getShortServiceName (service) {
      if (!service) return ''
      var name = getDivergentEndOfServiceName(service)
      if (name.length > 35) return name.substring(0, 32) + '...'
      return name
    }

    function getCommonStartOfServicesName () {
      if (!vm.allowedServices || vm.allowedServices.length <= 1) return ''

      var common = ''

      for (var currentCharIndex = 0; currentCharIndex < vm.allowedServices[0].content.length; currentCharIndex++) {
        var checkedChar = vm.allowedServices[0].content[currentCharIndex]
        var isCharCommon = !vm.allowedServices.find(function (service) {
          return service.content[currentCharIndex] !== checkedChar
        })

        if (isCharCommon) common += checkedChar
        else break
      }

      return common.length > 10 ? common : ''
    }

    function getDivergentEndOfServiceName (service) {
      return service.content.substring(getCommonStartOfServicesName().length)
    }
  }
})()
